<template>
  <v-container fluid>
    <v-toolbar dense>
      <v-icon class="mr-3">{{ icon }}</v-icon>
      <v-toolbar-title>
        <span> {{ $store.getters.translate(name) }}</span>
      </v-toolbar-title>
      <v-spacer/>
      <slot name="actions"/>
    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  props: ["name", "icon"],
};
</script>