<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" slot="header" dense flat>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-autocomplete
              class="mt-5"
              :label="$store.getters.translate('select_exams')"
              v-model="candidate_id"
              :items="candidates"
              :return-object="false"
              item-text="name"
              item-value="id"/>
            <v-autocomplete
              class="mt-5"
              :label="$store.getters.translate('select_exams')"
              v-model="scheme"
              :items="$lodash.sortBy(schemes, 'name')"
              :return-object="true"
              item-text="name"
              item-value="id"/>
            <div class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                v-if="loading_exams"
                class="ma-8"/>
            </div>
            <small v-if="no_exams_found" class="text-muted text-center red--text">{{ $store.getters.translate("no_exams") }}</small>
            <v-expansion-panels>
              <v-expansion-panel :disabled="registrationClosed(exam)" v-for="(exam, index) in exams" :key="index">
                <v-expansion-panel-header>
                  {{ exam.examination_date }}
                  <span v-if="exam.location"> - {{ exam.location.name }}</span>
                  {{ calcPrice(exam.sessions) }}
                  <small class="red--text" v-if="registrationClosed(exam)">({{ $store.getters.translate("full") }})</small>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list rounded dense>
                    <v-list-item-group v-model="selected_sessions" multiple>
                      <v-list-item
                        v-for="session in exam.sessions"
                        :key="session.id"
                        :value="session"
                        color="primary"
                        :disabled="selectableSessions(session)"
                        :class="selectableSessions(session) ? 'grey--text' : null">
                        <v-list-item-title :class="selectableSessions(session) ? 'grey--text' : null">
                          {{ session.name }}
                          <small v-if="selectableSessions(session)" class="mr-2">({{ $store.getters.translate("passed") }})</small>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card flat>
              <v-card-text>
                <div v-if="scheme && selected_sessions.length > 0" class="mt-2" v-html="scheme.prerequisites"/>
                <v-checkbox v-if="selected_sessions.length > 0" v-model="agree" :label="$store.getters.translate('i_agree')"/>
              </v-card-text>
            </v-card>
          </v-card-text>
        </div>
          <v-btn v-if="selected_sessions.length > 0" @click="save" :disabled="!agree" slot="footer" tile block color="primary">
            {{ $store.getters.translate("complete") }}
          </v-btn>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../../../plugins/helpFunctions";
import BaseModal from "../../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["payload", "all_candidates", "modal_name"],
  components: {BaseModal, BaseDraggableModal},
  data() {
    return {
      page: {
        title: this.$store.getters.translate("request_re_exam"),
      },
      record: {
        exams: [],
      },
      loading: false,
      scheme: null,
      exams: [],
      schemes: [],
      agree: false,
      selected_sessions: [],
      scheme_id: null,
      candidate_id: null,
      exam_id: null,
      exam: null,
      completed_sessions: [],
      loading_exams: false,
      no_exams_found: false,
      candidates: [],
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.selected_sessions = [];
      this.completed_sessions = [];
      this.$http
        .get(this.$store.getters.appUrl + "v2/published/schemes")
        .then((response) => {
          this.schemes = response.data;
          if (this.exam) {
            this.scheme = this.schemes.find((scheme) => scheme.id === this.exam.scheme_id);
          }
          if (this.all_candidates) {
            this.candidates = this.all_candidates;
          } else {
            this.exam.sessions.forEach((session) => {
              session.exam_candidate_sessions.forEach(
                (exam_candidate_session) => {
                  this.candidates.push(exam_candidate_session.candidate);
                }
              );
            });
          }
          if (this.candidates[0]) {
            this.candidate_id = this.candidates[0].id;
          }
          this.getSessions();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
    },
    getSessions() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/portal/candidate-sessions", {
          scheme_id: this.exam.scheme_id,
          candidate_id: this.candidate_id,
        })
        .then((response) => {
          this.completed_sessions = response.data;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
    },
    selectableSessions(session) {
      let disabled = false;
      this.completed_sessions.forEach((completed_session) => {
        if (completed_session.scheme_session_id === session.scheme_session_id && completed_session.status === "passed") {
          disabled = true;
        }
      });
      return disabled;
    },
    calcPrice(sessions) {
      let price = 0;
      sessions.forEach((session) => {
        price = price + session.price;
      });
      if (price == 0) {
        return null;
      }
      return "(€ " + price + ",00 excl. BTW)";
    },
    registrationClosed(exam) {
      let closed = false;
      exam.sessions.forEach((session) => {
        if (session.full) {
          closed = true;
        }
      });
      return closed;
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/portal/candidate-register-sessions", {
              session_ids: this.$lodash.map(this.selected_sessions, "id"),
              candidate_id: this.candidate_id,
            })
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("request_received"));
              this.closeModal(this.modal_name);
              this.$emit("refresh");
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
    },
    getExams() {
      if(this.scheme) {
        this.loading_exams = true;
        this.no_exams_found = false;
        this.$http
            .get(this.$store.getters.appUrl + "v2/published/exams/" + this.scheme.id)
            .then((response) => {
              this.exams = Object.values(response.data).filter((exam) => exam.id !== this.exam_id);
              if (this.exams.length > 0) {
                this.no_exams_found = false;
              } else {
                this.no_exams_found = true;
              }
              this.loading_exams = false;
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading_exams = false;
            });
      }
    },
  },
  watch: {
    candidate_id: {
      handler() {
        this.getSessions();
      },
    },
    scheme: {
      handler() {
        this.getExams();
      },
    },
  },
};
</script>
