import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"app":"","clipped":"","fixed":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.mobile ? '70%' : '300px',"value":_vm.show_sidebar}},[_c(VList,{staticClass:"ma-0 pa-0",attrs:{"flat":"","tile":""}},[_c(VListItem,{staticClass:"grey lighten-5"},[_c(VIcon,{attrs:{"color":"primary","small":"","left":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-arrow-right")]),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$store.getters.translate("messages"))+" ")]),(_vm.record.notifications.length !== 0)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","loading":_vm.loading_all,"color":"secondary","text":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteAllNotifications.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete-sweep")])],1)]}}],null,false,707615361)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("delete_all_notifications")))])])],1):_vm._e()],1),_c(VDivider),_vm._l((_vm.record.notifications),function(item,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c('small',{domProps:{"innerHTML":_vm._s(item.data.message)}}),_c(VListItemSubtitle,[_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.moment(item.created_at).format("DD-MM-YYYY")))])])],1),_c(VListItemAction,[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteNotification(item.id)}}},[_vm._v("mdi-trash-can")])],1)],1)}),(_vm.record.notifications.length === 0)?_c(VListItem,{staticClass:"text-center"},[_c('small',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$store.getters.translate("no_notifications")))])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }